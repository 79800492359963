/*
 * Application
 */
(function($) {
    "use strict";

    jQuery(document).ready(function($) {
        /*-------------------------------
        WINTER CMS FLASH MESSAGE HANDLING
        ---------------------------------*/
        $(document).on('ajaxSetup', function(event, context) {
            // Enable AJAX handling of Flash messages on all AJAX requests
            context.options.flash = true;

            // Enable the StripeLoadIndicator on all AJAX requests
            context.options.loading = $.oc.stripeLoadIndicator;

            // Handle Flash Messages
            context.options.handleFlashMessage = function(message, type) {
                $.oc.flashMsg({ text: message, class: type });
            };

            // Handle Error Messages
            context.options.handleErrorMessage = function(message) {
                $.oc.flashMsg({ text: message, class: 'error' });
            };
        });
    });
}(jQuery));

if (typeof(gtag) !== 'undefined' && typeof(gtag) !== 'function') {
    gtag = function() { console.log('GoogleAnalytics not present.'); }
}

// Navbar
let lastScrollY = 0;
const navbar = document.getElementById("navbar");
const logoContainer = document.getElementById("logo-container");

window.addEventListener("scroll", function() {
    const scrollY = window.scrollY;

    // Jika scroll bergerak ke bawah
    if (scrollY > 50 && scrollY > lastScrollY) {
        navbar.classList.add("scrolled");  // Menambahkan kelas untuk menyembunyikan logo
    } 
    // Jika scroll bergerak ke atas
    else if (scrollY < lastScrollY) {
        navbar.classList.remove("scrolled");  // Menghapus kelas dan menampilkan logo
    }

    // Menyimpan posisi scroll terakhir untuk perbandingan
    lastScrollY = scrollY;

    // Menggunakan requestAnimationFrame untuk memastikan transisi berlangsung lebih halus
    window.requestAnimationFrame(function() {
        if (scrollY > 50) {
            logoContainer.style.opacity = 0;
            logoContainer.style.visibility = 'hidden';
            logoContainer.style.maxHeight = '0';
            logoContainer.style.padding = '0';
        } else {
            logoContainer.style.opacity = 1;
            logoContainer.style.visibility = 'visible';
            logoContainer.style.maxHeight = '200px';  // Kembalikan tinggi max seperti sebelumnya
            logoContainer.style.padding = '12px 0 0 0';  // Kembalikan padding
        }
    });
});

// HeroSwiper
var swiper = new Swiper(".heroSwiper", {
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: {
        delay: 4000,
        disableOnInteraction: false,
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
});
// Accomodation Homepage Swiper
var swiper = new Swiper(".accomodationSwiper", {
    spaceBetween: 10,
    navigation: {
        nextEl: ".swiper-button-next-accommodation",
        prevEl: ".swiper-button-prev-accommodation",
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: function (index, className) {
        return '<span class="' + className + '">' + (index + 1) + "</span>";
        },
    },
});

// Accomodation Mobile Homepage Swiper
var swiper = new Swiper(".accomodationmobSwiper", {
    navigation: {
        nextEl: ".swiper-button-next-accommodationmob",
        prevEl: ".swiper-button-prev-accommodationmob",
    },
});
// Guest Review Homepage Swiper
var swiper = new Swiper(".guestrevSwiper", {
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: {
        delay: 4000,
        disableOnInteraction: false,
    },
    navigation: {
        nextEl: ".swiper-button-next-nearbymob",
        prevEl: ".swiper-button-prev-nearbymob",
    },
});
// Facilities details swiper
var swiper = new Swiper(".facdetailsSwiper", {
    slidesPerView: 3,
    spaceBetween: 33,
    navigation: {
        nextEl: ".swiper-button-next-facdetails",
        prevEl: ".swiper-button-prev-facdetails",
    },
});
// Footer Swiper
var swiper = new Swiper(".footerSwiper", {
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: {
        delay: 2500,
        disableOnInteraction: false,
    },
});
// Footer Mobile Swiper
var swiper = new Swiper(".footermobileSwiper", {
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: {
        delay: 2500,
        disableOnInteraction: false,
    },
});
// Fungsi accommodation-details
// Fungsi openTab untuk mengubah konten yang ditampilkan
function openTab(evt, tabName) {
    console.log("Tab dibuka: " + tabName); // Debugging: Melihat tab yang sedang dibuka
    // Sembunyikan semua konten tab
    var tabContents = document.querySelectorAll('.tab-content');
    tabContents.forEach(content => {
        content.classList.add('hidden');  // Menyembunyikan konten yang tidak aktif
        content.classList.remove('block'); // Menghapus flex dari konten yang tidak aktif
    });
    // Hapus gaya aktif dari semua tombol
    var tabButtons = document.querySelectorAll('.tab-button');
    tabButtons.forEach(button => {
        button.classList.remove('text-[#A57D2D]', 'border-b-[3px]', 'border-[#A57D2D]');
        button.classList.add('text-[#9D9D9D]', 'border-b');
    });
    // Tampilkan konten tab yang dipilih dan beri gaya aktif pada tombol yang diklik
    const selectedContent = document.getElementById(tabName);
    if (selectedContent) {
        selectedContent.classList.remove('hidden');
        selectedContent.classList.add('block'); // Menampilkan konten dengan flex
    } else {
        console.log("Konten tab tidak ditemukan: " + tabName); // Debugging jika elemen tidak ditemukan
    }

    evt.currentTarget.classList.add('text-[#A57D2D]', 'border-b-[3px]', 'border-[#A57D2D]');
    evt.currentTarget.classList.remove('text-[#9D9D9D]', 'border-b','border-[#9D9D9D]');
}
// Tambahkan event listener ke setiap tombol tab setelah halaman dimuat
document.addEventListener('DOMContentLoaded', function() {
    document.querySelectorAll('.tab-button').forEach(button => {
        button.addEventListener('click', function(event) {
            const tabName = button.getAttribute('data-tab');
            openTab(event, tabName);
        });
    });
});

// Fungsi Special Offers details
function openOffersTab(evt, tabName) {
    console.log("Tab Special Offers dibuka: " + tabName); // Debugging: Melihat tab yang sedang dibuka
    // Sembunyikan semua konten tab
    var tabContents = document.querySelectorAll('.tab-content-offers');
    tabContents.forEach(content => {
        content.classList.add('hidden');  // Menyembunyikan konten yang tidak aktif
        content.classList.remove('block'); // Menghapus flex dari konten yang tidak aktif
    });
    // Hapus gaya aktif dari semua tombol
    var tabButtons = document.querySelectorAll('.tab-button-offers');
    tabButtons.forEach(button => {
        button.classList.remove('text-[#A57D2D]', 'border-b-[3px]', 'border-[#A57D2D]');
        button.classList.add('text-[#9D9D9D]', 'border-b');
    });
    // Tampilkan konten tab yang dipilih dan beri gaya aktif pada tombol yang diklik
    const selectedContent = document.getElementById(tabName);
    if (selectedContent) {
        selectedContent.classList.remove('hidden');
        selectedContent.classList.add('block'); // Menampilkan konten dengan flex
    } else {
        console.log("Konten tab tidak ditemukan: " + tabName); // Debugging jika elemen tidak ditemukan
    }
    evt.currentTarget.classList.add('text-[#A57D2D]', 'border-b-[3px]', 'border-[#A57D2D]');
    evt.currentTarget.classList.remove('text-[#9D9D9D]', 'border-b','border-[#9D9D9D]');
}

// Tambahkan event listener ke setiap tombol tab setelah halaman dimuat
document.addEventListener('DOMContentLoaded', function() {
    document.querySelectorAll('.tab-button-offers').forEach(button => {
        button.addEventListener('click', function(event) {
            const tabName = button.getAttribute('data-tab');
            openOffersTab(event, tabName); // Panggil openOffersTab untuk special offers
        });
    });
});
// Nav Mobile Icons
const icons = document.querySelectorAll('.icon');
icons.forEach (icon => {  
    icon.addEventListener('click', (event) => {
        icon.classList.toggle("open");
    });
});


// Navbar Mobile
document.addEventListener('DOMContentLoaded', function() {
    const toggleDrawerButton = document.getElementById('toggleDrawerButton');
    const drawer = document.getElementById('drawer');

    // Fungsi untuk toggle status drawer
    toggleDrawerButton.addEventListener('click', function() {
        if (drawer.classList.contains('show')) {
            // Jika drawer sudah terbuka, sembunyikan kembali
            drawer.classList.remove('show');
        } else {
            // Jika drawer belum terbuka, tampilkan dan mulai animasi
            drawer.classList.add('show');
        }
    });
});
document.addEventListener('DOMContentLoaded', function () {
    const menus = [
        {
            toggleButton: document.getElementById('toggle-menu-accmob'),
            menuItems: document.querySelectorAll('#menuAccmob .menu-item-accmob'),
            toggleIcon: document.getElementById('toggle-icon-accmob'),
        },
        {
            toggleButton: document.getElementById('toggle-menu-facmob'),
            menuItems: document.querySelectorAll('#menuFacmob .menu-item-facmob'),
            toggleIcon: document.getElementById('toggle-icon-facmob'),
        },
        {
            toggleButton: document.getElementById('toggle-menu-actmob'),
            menuItems: document.querySelectorAll('#menuActmob .menu-item-actmob'),
            toggleIcon: document.getElementById('toggle-icon-actmob'),
        },
        {
            toggleButton: document.getElementById('toggle-menu-hotelmob'),
            menuItems: document.querySelectorAll('#menuHotelmob .menu-item-hotelmob'),
            toggleIcon: document.getElementById('toggle-icon-hotelmob'),
        }
    ];

    // Filter out invalid menus (elements not found in DOM)
    const validMenus = menus.filter(menu =>
        menu.toggleButton && menu.menuItems.length > 0 && menu.toggleIcon
    );

    // Fungsi untuk menutup semua menu
    function closeAllMenus() {
        validMenus.forEach(({ menuItems, toggleIcon, toggleButton }) => {
            menuItems.forEach(item => {
                item.classList.add('opacity-0', '-translate-y-4');
                setTimeout(() => {
                    item.classList.add('hidden');
                }, 300); // Match the transition duration
            });
            toggleIcon.classList.remove('rotate-270');
            toggleButton.classList.remove('text-[#A57D2D]');
        });
    }

    // Tambahkan event listener ke setiap toggle
    validMenus.forEach(({ toggleButton, menuItems, toggleIcon }) => {
        toggleButton.addEventListener('click', (e) => {
            const clickedElement = e.target;

            // Jika ikon diklik, toggle menu
            if (clickedElement.tagName === 'I') {
                e.preventDefault();

                const isCurrentlyOpen = !menuItems[0].classList.contains('hidden');

                // Tutup semua menu terlebih dahulu
                closeAllMenus();

                if (!isCurrentlyOpen) {
                    // Tambahkan sedikit delay untuk memastikan penutupan selesai
                    setTimeout(() => {
                        menuItems.forEach(item => {
                            item.classList.remove('hidden');
                            setTimeout(() => {
                                item.classList.remove('opacity-0', '-translate-y-4');
                            }, 10); // Slight delay to allow transition to take effect
                        });
                        toggleIcon.classList.add('rotate-270');
                        toggleButton.classList.add('text-[#A57D2D]');
                    }, 310); // Pastikan lebih lama dari durasi penutupan
                }
            }
        });
    });
});

document.addEventListener('DOMContentLoaded', function () {
    const currentPage = window.location.pathname;

    // Periksa halaman berdasarkan URL
    if (currentPage.includes('/accommodation/')) {
        initDropdownAccommodations();
    } else if (currentPage.includes('/specialoffers/')) {
        initDropdownSpecialOffers();
    }
});

function initDropdownAccommodations() {
    const menus = [
        {
            toggleButton: document.getElementById('toggle-menu-overviewmob'),
            menuItems: document.querySelectorAll('#menuOverviewmob .menu-item-overviewmob'),
            toggleIcon: document.getElementById('toggle-icon-overviewmob'),
        },
        {
            toggleButton: document.getElementById('toggle-menu-amenitiesmob'),
            menuItems: document.querySelectorAll('#menuAmenitiesmob .menu-item-amenitiesmob'),
            toggleIcon: document.getElementById('toggle-icon-amenitiesmob'),
        },
        {
            toggleButton: document.getElementById('toggle-menu-layoutmob'),
            menuItems: document.querySelectorAll('#menuLayoutmob .menu-item-layoutmob'),
            toggleIcon: document.getElementById('toggle-icon-layoutmob'),
        },
    ];
    setupDropdownMenus(menus);
}

function initDropdownSpecialOffers() {
    const menus = [
        {
            toggleButton: document.getElementById('toggle-menu-overviewoffersmob'),
            menuItems: document.querySelectorAll('#menuOverviewoffersmob .menu-item-overviewoffersmob'),
            toggleIcon: document.getElementById('toggle-icon-overviewoffersmob'),
        },
        {
            toggleButton: document.getElementById('toggle-menu-Termsoffersmob'),
            menuItems: document.querySelectorAll('#menuTermsoffersmob .menu-item-termsoffersmob'),
            toggleIcon: document.getElementById('toggle-icon-termsoffersmob'),
        },
    ];
    setupDropdownMenus(menus);
}

function setupDropdownMenus(menus) {
    // Fungsi untuk menutup semua menu
    function closeAllMenus() {
        menus.forEach(({ menuItems, toggleIcon, toggleButton }) => {
            if (menuItems.length && toggleIcon && toggleButton) {
                menuItems.forEach(item => {
                    item.classList.add('opacity-0', '-translate-y-4');
                    setTimeout(() => {
                        item.classList.add('hidden');
                    }, 300); // Durasi transisi
                });
                toggleIcon.classList.remove('rotate-180');
                toggleButton.classList.remove('text-[#A57D2D]');
            }
        });
    }

    // Tambahkan event listener ke setiap tombol toggle
    menus.forEach(({ toggleButton, menuItems, toggleIcon }) => {
        if (toggleButton && menuItems.length && toggleIcon) {
            toggleButton.addEventListener('click', (e) => {
                e.preventDefault();

                const isCurrentlyOpen = !menuItems[0].classList.contains('hidden');

                closeAllMenus();

                if (!isCurrentlyOpen) {
                    setTimeout(() => {
                        menuItems.forEach(item => {
                            item.classList.remove('hidden');
                            setTimeout(() => {
                                item.classList.remove('opacity-0', '-translate-y-4');
                            }, 10);
                        });
                        toggleIcon.classList.add('rotate-180');
                        toggleButton.classList.add('text-[#A57D2D]');
                    }, 310);
                }
            });
        } else {
            console.warn('Element toggleButton, menuItems, atau toggleIcon tidak ditemukan:', { toggleButton, menuItems, toggleIcon });
        }
    });
}

var swiper = new Swiper(".mySwiperDining", {
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false
    },
});
